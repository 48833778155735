
// Contact.js 
import { React, useState, useContext, useEffect } from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { get, put } from '../rest'
import { GlobalContext } from '../App'
import Template from '../components/Template'
import { ChannelHeader } from '../components/helpers';
import ReportInfo from '../components/ReportInfo';

export default function Success() {
  const global = useContext(GlobalContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { reportId } = useParams()

  useEffect(() => {
    if (global.token && !global.report && reportId && !loading) {
      setLoading(true)
      get('report/' + reportId, global.token)
        .then(response => {
          global.setReport(response.report)
          global.setChannel(response.report.channel)
        })
        .catch(err => { global.setError('Error loading report information') })
        .finally(() => setLoading(false))
    }
  }, [global, loading, reportId])

  const attachEmail = (e) => {
    e.preventDefault()
    put('user', { email }, global.token)
      .then(() => navigate('/download'))
      .catch((err) => { global.setError('Error submitting email address') })
  }

  return (
    <Template>
      <ChannelHeader channel={global.channel} />
      <ReportInfo report={global.report} />
      <hr />
      <h4>Thanks for your report!</h4>
      <p>
        Please leave us your email address and we'll send you updates about your issue
        and information about how to join the Trubbl community.
      </p>
      <p style={{ fontSize: 11, marginBottom: 15 }}>
        We'll never share your email with anyone without your permission.
      </p>
      <FloatingLabel
        controlId="floatingInput"
        label="Email address"
        className="mb-3"
      >
        <Form.Control
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FloatingLabel>
      <div className="d-flex justify-content-center">
        <Button
          className='submit-button mb-4'
          style={{ marginRight: 10 }}
          variant="primary"
          type="submit"
          onClick={attachEmail}>
          Submit
        </Button>
        <Button
          className='submit-button mb-4'
          variant="secondary"
          onClick={() => navigate('/download')}>
          Skip
        </Button>

      </div>
    </Template>
  )
}
