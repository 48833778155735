import { useLayoutEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { mediaUri } from "../rest"

/**
 * Add a list of media
 *
 * @param {*} param0
 * @returns
 */
export default function AddMedia({ media, setMedia }) {
  const [width, setWidth] = useState(0)
  const mediaBox = useRef(null)

  /**
   * Get the width of the media box and use it to set 
   * the width variable. This will force a rerender
   * that allows calculations based on the width of the
   * container.
   * 
   * Note that adding dependencies to the useLayoutEffect
   * prevents it from working.
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const w = mediaBox && mediaBox.current
      ? mediaBox.current.offsetWidth
      : 0
    setWidth(w)
  })

  /**
   * Add new files to media list
   *
   * @param {*} files
   */
  const onDrop = (files) => {
    const newMedia = removeDuplicateMedia(media.concat(files))
    setMedia(newMedia.splice(0, 8))
  }

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 8,
    onDrop,
    accept: { "image/*": [] },
  })

  const columns = 4
  const margin = width / 50
  const boxWidth = (width - margin * (columns - 1)) / columns
  const endBoxes = columns - media.length % columns
  const endWidth = endBoxes * boxWidth + (endBoxes - 1) * margin
  const addPhotosHeight = media.length === 0 ? 40 : boxWidth

  /**
   * Display single media item
   */
  const mediaItem = (m, p) => {
    const marginRight = (p + 1) % columns === 0 ? 0 : margin

    return (
      <div key={p} style={{
        position: "relative",
        height: boxWidth,
        width: boxWidth,
        marginRight,
        marginBottom: margin
      }}>
        <ion-icon
          style={{
            position: "absolute",
            top: 5,
            left: 5,
            backgroundColor: "white",
            opacity: 0.75,
            borderRadius: 9,
            width: 18,
            height: 18,
          }}
          name="close-outline"
          onClick={() => remove(m)}
        />
        <img
          src={URL.createObjectURL(m)}
          style={{ height: '100%', width: '100%' }}
          alt={"Photo " + p}
        />
      </div>
    )
  }

  /**
   * Remove file from media list
   *
   * @param {*} m
   */
  const remove = (m) => {
    const newMedia = removeMedia(media, m)
    setMedia(newMedia)
  }

  return (
    <div ref={mediaBox} style={{
      marginBottom: 15,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row'
    }} >
      {media.map((m, p) => mediaItem(m, p))}
      {media.length >= 8 ? null :
        <div {...getRootProps()} onClick={open}>
          <input {...getInputProps()} />
          <div style={{
            width: endWidth,
            height: addPhotosHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f2e8ec',
            borderRadius: 3
          }}>
            <span style={{ textAlign: 'center', padding: 15 }}>Tap to add photos</span>
          </div>
        </div>
      }
    </div>
  )
}

/**
 * Add a list of media
 *
 * @param {*} param0
 * @returns
 */
export function ShowMedia({ media }) {
  const [width, setWidth] = useState(0)
  const mediaBox = useRef(null)

  /**
   * Get the width of the media box and use it to set 
   * the width variable. This will force a rerender
   * that allows calculations based on the width of the
   * container.
   * 
   * Note that adding dependencies to the useLayoutEffect
   * prevents it from working.
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const w = mediaBox && mediaBox.current
      ? mediaBox.current.offsetWidth
      : 0
    setWidth(w)
  })

  // check for no media
  if (!media || media.length === 0) {
    return null
  }

  const columns = 4
  const margin = width / 50
  const boxWidth = (width - margin * (columns - 1)) / columns

  /**
   * Display single media item
   */
  const mediaItem = (m, p) => {
    const marginRight = (p + 1) % columns === 0 ? 0 : margin
    console.log(m)

    return (
      <div key={p} style={{
        height: boxWidth,
        width: boxWidth,
        marginRight,
        marginBottom: margin
      }}>
        <img
          src={mediaUri(m)}
          style={{ height: '100%', width: '100%' }}
          alt={"Photo " + p}
        />
      </div>
    )
  }

  return (
    <div ref={mediaBox} style={{
      marginTop: 15,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row'
    }} >
      {media.map((m, p) => mediaItem(m, p))}
    </div>
  )
}

/**
 * Return true if same media item
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
function isSameMedia(a, b) {
  return (
    a.path === b.path &&
    a.name === b.name &&
    a.size === b.size &&
    a.type === b.type
  )
}

/**
 * Return list with duplicate media filtered out
 *
 * @param {*} l
 * @returns
 */
function removeDuplicateMedia(l) {
  return l.filter((m, i) => {
    return l.findIndex((mm) => isSameMedia(m, mm)) === i
  })
}

/**
 * Return list with media item indicated removed
 *
 * @param {*} l
 * @returns
 */
function removeMedia(l, m) {
  return l.filter((mm, i) => !isSameMedia(m, mm))
}
