import { ShowMedia } from "./AddMedia"
import { shorten } from "./helpers"


/**
 * Display the report information
 */
export default function ReportInfo({ report }) {
  if (!report) return null

  return (
    <div style={{ marginBottom: 15 }}>
      <h4 className="trubbl-color" style={{ marginBottom: 0 }}>Subject:</h4>
      {report.subject}
      {!report.details ? null : <>
        <h4 className="trubbl-color" style={{ marginBottom: 0, marginTop: 15 }}>Details:</h4>
        {shorten(report.details, 90)}
      </>}
      <ShowMedia media={report.media} />
    </div>
  )
}
