import { Container } from 'react-bootstrap'
import '../App.css'
import powered from '../assets/logo-powered-by.png'

/**
 * Show template with padding for scrolling
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Template({ className, children }) {
  return (
    <TemplateNoScroll className={className} >
      {children}
      <div style={{ height: 100 }} />
    </TemplateNoScroll>
  )
}

/**
 * Show template
 * 
 * @param {*} param0 
 * @returns 
 */
export function TemplateNoScroll({ className, children }) {
  className = "pt-3 pb-3 " + className
  
  return (
    <Container className={className}>
      {children}
      <PoweredLogo />
    </Container>    
  )
}  

/**
 * Signup link text
 */
export function PoweredLogo() {
  return (
    <div className="text-center fixed-bottom pb-3 pt-2" style={{ backgroundColor: 'white' }}>
      <img src={powered} width='20%' className="img-fluid" alt="powered" />
    </div>
  )
}