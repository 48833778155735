import '../App.css'
import { Form, Button } from 'react-bootstrap'
import { useEffect, useContext, useState } from 'react'
import { post, get, postReportMedia } from '../rest'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../App'
import { LoadingMessage } from '../components/Messages'
import AddMedia from '../components/AddMedia'
import Template from '../components/Template'
import { ChannelHeader } from '../components/helpers'

/**
 * The Report page expects a channelId that is the 
 * channel where the report will be posted.
 */
export default function Report() {
  const navigate = useNavigate()
  const global = useContext(GlobalContext)
  const { channelId } = useParams()
  const [subject, setSubject] = useState('')
  const [details, setDetails] = useState('')
  const [media, setMedia] = useState([])
  const [loading, setLoading] = useState(false)
  const [savingText, setSavingText] = useState("")

  useEffect(() => {
    if (global.token && channelId && !global.channel && !loading) {
      setLoading(true)
      get('channel/' + channelId, global.token)
        .then(response => global.setChannel(response.channel))
        .catch(err => { global.setError('Error loading channel information') })
        .finally(() => setLoading(false))
    }
  }, [global, loading, channelId])

  /**
   * Create report
   */
  const handlePostReport = e => {
    e.preventDefault()
    const postReport = async () => {
      setSavingText("Saving report...")
      const reportData = {
        subject,
        details,
        addChannels: [{ _id: channelId }],
      }

      const response = await post("report", reportData, global.token)
      const report = response.report
      global.setReport(report)

      let mediaCount = 0
      for (const m of media) {
        mediaCount++
        setSavingText(
          "Saving media " + mediaCount + " of " + media.length + "...",
        )
        await postReportMedia(response.report, m, global.token)
      }

      return report
    }

    postReport()
      .then(report => navigate('/success/' + report._id))
      .catch((err) => { global.setError('Error submitting report') })
      .finally(() => setSavingText(""))
  }

  if (!global.channel) {
    return <LoadingMessage message="" />
  }

  return (
    <Template>
      <ChannelHeader channel={global.channel} />
      <AddMedia media={media} setMedia={setMedia} />
      <Form>
        <Form.Group className="mb-4" controlId="trubbl-color">
          <Form.Label className='trubbl-color'>
            Subject:
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="What is the problem (required)..."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="trubbl-details">
          <Form.Label className='trubbl-color'>
            Details:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            placeholder="Tell us a bit more about the problem..."
            value={details}
            onChange={(e) => setDetails(e.target.value)} />
        </Form.Group>
        <div className='text-center'>
          {savingText
            ?
            <p>{savingText}</p>
            :
            <Button
              className='submit-button mb-4'
              variant={subject ? 'primary' : 'secondary'}
              disabled={!subject}
              type="submit"
              onClick={handlePostReport}
            >
              Submit Trubbl Report
            </Button>
          }
        </div>
      </Form>
    </Template>
  )
}
