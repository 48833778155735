// Error classes for alerts and API
export class AlertError extends Error { }
export class APIError extends AlertError { }

/**
 * Calculate the url based on the environment (dev or not) and
 * whether it is an emulator or a real device
 *
 * @returns url string
 */
function apiUrl() {
  const hostname = window.location.hostname
  if (hostname === 'localhost' || hostname.startsWith('192.')) {
    return 'http://localhost:3601'
  } else if (hostname.includes('-t')) {
    return 'https://api-t.trubbl.app'
  } else {
    return 'https://api.trubbl.app'
  }
}

/**
 * GET fetch operation
 *
 * @param {*} endpoint
 * @param {*} token
 * @returns json
 */
export async function get(endpoint, token, controller) {
  return await op('GET', endpoint, null, token, controller)
}
/**
 * PUT fetch operation
 *
 * @param {*} endpoint
 * @param {*} body
 * @param {*} token
 * @returns json
 */
export async function put(endpoint, body, token, controller) {
  return await op('PUT', endpoint, body, token, controller)
}
/**
 * POST fetch operation
 *
 * @param {*} endpoint
 * @param {*} body
 * @param {*} [token]
 * @returns
 */
export async function post(endpoint, body, token, controller) {
  return await op('POST', endpoint, body, token, controller)
}
/**
 * DELETE fetch operation
 *
 * @param {*} endpoint
 * @param {*} token
 * @returns json
 */
export async function del(endpoint, token, controller) {
  return await op('DELETE', endpoint, null, token, controller)
}
/**
 * Create headers, request options and perform fetch
 * operation and handle errors
 *
 * @param {*} operation
 * @param {*} endpoint
 * @param {*} [body]
 * @param {*} [token]
 * @returns json
 */
async function op(operation, endpoint, body, token, controller) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  // add token to header is present
  if (token) {
    // check for valid token type
    if (!(typeof token === 'string' || token instanceof String)) {
      throw new Error('Invalid token type')
    }
    headers['X-Access-Token'] = token
  }
  // create request options
  const reqOptions = {
    method: operation,
    headers: headers,
  }
  // add signal if controller present
  if (controller) {
    reqOptions.signal = controller.signal
  }
  // add body if present in request
  if (body) {
    if (typeof body !== 'object') {
      throw new Error('Invalid body type')
    }
    reqOptions.body = JSON.stringify(body)
  }
  return await _op(endpoint, reqOptions)
}
/**
 * Perform fetch operation and handle errors
 *
 * @param {*} url
 * @param {*} reqOptions
 * @returns
 */
async function _op(endpoint, reqOptions) {
  if (endpoint.startsWith('/')) {
    throw new Error('Endpoint should not start with slash')
  }
  const url = apiUrl() + '/' + endpoint
  let response, json
  console.log(reqOptions.method + ' ' + url)

  response = await fetch(url, reqOptions)
  // invalid endpoint was used
  if (response.status === 404) {
    console.log(url + ' not found (404)')
    throw new APIError('Invalid network request')
  }
  // log the response
  const text = response.clone().text()
  // get json returned from api
  try {
    json = await response.json()
  } catch (err) {
    console.log('Invalid json response: ' + text)
    throw new APIError('Invalid json response')
  }
  if (response.status !== 200) {
    console.log(json.error.message)
    throw new APIError(json.error.message)
  }
  return json
}

/**
* POST photo for report
* 
* @param {*} photo 
* @param {*} token 
* @returns json
*/
export async function postReportMedia(report, media, token) {
  const body = new FormData()
  body.append('media_file', media)

  const headers = {
    'X-Access-Token': token,
    'Accept': 'application/json',
  }

  return await _op('report/media/' + report._id, { method: 'POST', headers, body })
}

/**
 * Return uri for media id
 * 
 * @param {*} id 
 * @param {*} thumbnail 
 * @returns 
 */
export function mediaUri(id, thumbnail = false) {
  return apiUrl() + (thumbnail ? "/thumbnail/" : "/image/") + id
}
