
import React from 'react';
import appleDownload from '../assets/apple-download-badge.svg'
import trubblLogo from '../assets/logo-300.png'
import googleDownload from '../assets/google-play-badge.png'
import { Row, Col } from 'react-bootstrap'
import Template from '../components/Template';

export default function Download() {
  return (
    <Template className='d-flex justify-content-center align-items-center'>
      <Row>
        <Col className="text-center">
          <img src={trubblLogo} className="img-fluid mb-3" alt="powered" />
          <p className='mb-3'>
            Trubbl is the universal, community-driven reporting app that helps users
            stay updated on discovered issues. Download the app to contribute reports and
            stay updated on issues relevant to the places you frequent.
          </p>
          <img src={appleDownload} width={150} className="img-fluid" alt="powered" />
          <img src={googleDownload} width={190} className="img-fluid" alt="powered" />
        </Col>
      </Row>
    </Template>
  )
}
