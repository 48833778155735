/**
 * Display the channel header
 */
export function ChannelHeader({ channel }) {
  if (!channel) return null

  return (
    <h1 className='text-center mb-4 channel-title'>
      {channel.name}
    </h1>
  )
}

/**
 * Shorten string and add elipsis
 *
 * @param s
 * @param l
 * @param elipsis
 * @returns
 */
export function shorten(s, l, elipsis = "...") {
  if (s.length <= l) return s.trimEnd()
  s = s.substring(0, l)
  const t = Math.min(s.length, s.lastIndexOf(" "))
  return (t === 0 ? s : s.substring(0, t)).trimEnd() + elipsis
}