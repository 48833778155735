import { Spinner, Button } from 'react-bootstrap'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { TemplateNoScroll } from './Template.js'

/**
 * Display message in center
 * 
 * @param {*} param0 
 * @returns 
 */
export function CenterMessage({ children }) {
  return (
    <TemplateNoScroll className='d-flex flex-column text-center justify-content-center align-items-center'>
      {children}
    </TemplateNoScroll>
  )
}

/**
 * Display loading message in center with spinner
 * 
 * @param {*} param0 
 * @returns 
 */
export function LoadingMessage({ message }) {
  return (
    <CenterMessage>
      <h1>{message}</h1>
      <Spinner animation="grow" variant='secondary' />
    </CenterMessage>
  )
}

/**
 * Display error messaage in center with retry button
 * 
 * @param {*} param0 
 * @returns 
 */
export function ErrorMessage({ message }) {
  const navigate = useNavigate()

  /**
   * Reload the page
   */
  const reload = e => {
    e.preventDefault()
    navigate(0)
  }

  return (
    <CenterMessage>
      <h1>{message}</h1>
      <Button
        className='submit-button mb-4'
        variant="primary"
        type="submit"
        onClick={reload}>
        Try Again
      </Button>
    </CenterMessage>
  )
}