import './App.css'
import { useEffect, useState, createContext, useContext } from 'react'
import { post } from './rest.js'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js'
import Success from './pages/Success.js'
import Report from './pages/Report.js'
import Download from './pages/Download.js'
import Cookies from 'universal-cookie'
import { ErrorMessage } from './components/Messages.js';

export const GlobalContext = createContext({
  token: '',
  setToken: (token) => { },
  channel: null,
  setChannel: (channel) => { },
  report: null,
  setReport: (report) => { },
})

function App() {
  const [channel, setChannel] = useState(null)
  const [report, setReport] = useState(null)
  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)

  const state = {
    token, setToken,
    channel, setChannel,
    report, setReport,
    error, setError,
  }

  useEffect(() => {
    const cookies = new Cookies()
    const getToken = async () => {
      let response = await post('register/anonymous')
      const token = response.accessToken
      cookies.set('token', token, { path: '/' })
      setToken(token)
    }

    // get token from cookie
    const token = cookies.get('token', { path: '/' })
    if (token) {
      setToken(token)
    } else {
      getToken().catch(err => setError('Unable to start report'))
    }
  }, [])

  return (
    <GlobalContext.Provider value={state} >
      <AppRouter />
    </GlobalContext.Provider>
  )
}

function AppRouter() {
  const error = useContext(GlobalContext).error
  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={< Home />}></Route>
        <Route path='/success/:reportId' element={<Success />}></Route>
        <Route path='/download' element={<Download />}></Route>
        <Route path='/:channelId' element={<Report />}></Route>
      </Routes>
    </div>
  )
}

export default App


