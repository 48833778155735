// About.js 
import React from 'react';
import { Link } from 'react-router-dom';
import Template from '../components/Template';

function Home() {
  return (
    <Template>
      <h1>Report Trubbl</h1>
      <p>
        This is a reporting app for Trubbl. It is generally used with a QR code that
        directs you to enter a report for a specific channel.
      </p>
      <p>
        If you arrived here by mistake, let us know by tapping on the link
        below and submitting a report.
      </p>
      <Link to="/65dfd540395ba2d2b9e6063e">Trubbl Feedback and Issues</Link>
    </Template>
  )
}
export default Home